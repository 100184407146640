import React, { Component } from 'react';
import Header from "./header/Header" // Nagłowek strony
import Menu from "./menu/Menu" // Menu nawigacji
import About from "./about/About" // 'o nas '
import Offer from "./offer/Offer" // offerta firmy
import Contact from "./contact/Contact" // konakt
import Footer from "./footer/Footer" // stopka
import Fb from './fb/fb' // wysuwalne okienko facebook
import PageClosed from './PageClosed';

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props)

    // nadaje refy , które wykorzystam przy nawigacji

    this.Refs = [
      {
        Ref: React.createRef(),
        name: 'O firmie'
      },
      {
        Ref: React.createRef(),
        name: 'Oferta'
      },
      {
        Ref: React.createRef(),
        name: 'Kontakt'
      }
    ]

    this.names = this.Refs.map((elem) => elem.name)
  }

  // ----- animacja scrollowania do elementu -----

  scrollTo = (name) => {
    console.log(this.Refs)
    this.Refs.forEach(element => {
      if (element.name === name) {
        element.Ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    })
  }

  render() {
    return (
      <div className="App">
        <Header />
        <Menu names={this.names} click={this.scrollTo} />
        <Fb />
        <About Ref={this.Refs[0].Ref} />
        <Offer Ref={this.Refs[1].Ref} />
        <Contact Ref={this.Refs[2].Ref} />
        <Footer />

      </div>
    );
  }
}


export default App;
