import React, { Component } from 'react';
import MapComponent from './Map' // komponent mapy
import './Contact.scss' // arkusz stylów

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 49.88123,
      lng: 20.1179,
      zoom: 13,
      phoneDiv: '513 ... kliknij, aby zobaczyć ',
      emailDiv: 'topam ... kliknij, aby zobaczyć ',
      phone: '513 577 276 ',
      email: 'topamalgorzata32@gmail.com'
    };
  }
  showNumber = () => { // po kliknięciu pokazuje numer telefonu
    this.setState({
      phoneDiv: this.state.phone
    })
  }
  showEmail = () => { // po kliknięciu pokazuje email
    this.setState({
      emailDiv: this.state.email
    })
  }

  render() {
    return (
      <div id="contact" ref={this.props.Ref}>
        <h1 className='heading'>
          Kontakt
        </h1>

        <div className="dataDiv">
          Pracownia krawiecka "Gosia"<br />
          Małgorzata Topa <br />
          Skrzynka 32<br />
          32-410 Dobczyce <br />
          NIP:681-206-50-04 <br /><br />

          Email: <span style={{ color: '#74479b', fontWeight: 'bold' }}> <button onClick={this.showEmail} className='showButton' id='emailButton'> {this.state.emailDiv} </button> </span>   <br />
          Telefon: <span style={{ color: '#74479b', fontWeight: 'bold' }}> <button onClick={this.showNumber} className='showButton' id='phoneButton'> {this.state.phoneDiv} </button> </span>  <br /><br />
          Godziny otwarcia:<br />
          poniedziałek-piątek: 15.00 - 19.00<br />
          sobota: 8.00 - 18.00

          {/* <div id='alert'>Pracownia nieczynna do 04.07.2022 z powodu urlopu</div> */}
        </div>

        <div id="mapDiv">
          <MapComponent zoom={this.state.zoom} position={[this.state.lat, this.state.lng]} />
        </div>

      </div>
    );
  }
}
