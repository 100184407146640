import React, { Component } from 'react';
import Service from './Service' // komponent z usługą
import './Offer.scss'; // arkusz stylów

// iconki usług
import Manekin from '../assets/manekin.png' // iconka manekinu
import window from '../assets/window.png' // iconka manekinu

export default class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offer:
        [
          {
            name: "Szycie firan i zasłon",
            img: window,
            offers: [
              'obszycie zwykłe: 2.50zł / mb',
              'obszycie lamówką: 2.50zł / mb(+ koszt lamówki - 2zł)',
              'wszyscie taśmy: 5zł / mb(+ koszt taśmy - 2zł)',
              'skracanie firan, zasłon: 5zł / mb',
            ]


          },

          {
            name: "Przeróbki krawieckie",
            img: Manekin,
            offers: [
              'skrócenie spodni: 10zł',
              'skrócenie spodni na podszywarce: 12zł',
              'skrócenie spodni garniturowych z tasiemką: 20zł',
              'wszycie zamka do spodni/ spódnicy: 13zł(+ koszt zamka)',
              'wszycie zamka do sukienki: 18zł(+ koszt zamka)',
              'wszycie zamka do kurtki: 25-40zł(+ koszt zamka)',
              'przeróbki sukien ślubnych',
            ]

          },

        ]
    };
  }

  render() {
    var offer = this.state.offer.map((i, index) => { // dynamiczne tworzenie divow z ofertami
      return <Service key={index} name={i.name} img={i.img} offers={i.offers} />
    })
    return (
      <div id='offer' ref={this.props.Ref}>

        <div className='heading'>
          Oferta
        </div>

        <div className='dataDiv'>
          Przykładowe usługi dostępne w naszej firmie :
        </div>

        <div id='offerList'>
          {offer}
        </div>

        <div className='dataDiv'>
          Reszta usług jest wyceniana na miejscu na podstawie ich rodzaju i czasu pracy.
        </div>
      </div>
    );
  }
}
