import React, { Component } from 'react';

export default class PageClosed extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div id='offer' >
                <h1>Strona tymczasowo nieczynna</h1>
            </div>
        );
    }
}
