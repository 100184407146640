import React, { Component } from 'react';
import './Header.scss' // style strony

import bannerImage from '../assets/header/banner.png' // banner

export default class Header extends Component {
  render() {
    return (
      <div className="headerDiv">
        <img className='bannerImage' src={bannerImage} alt='bannerImage' />
      </div>
    );
  }
}
